<template>
  <div id="orders">
    <Navigation />
    <v-main class="content mb-9" style="position: relative">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="สรุปออเดอร์" />
            <v-col cols="12" class="px-0 mb-7">
              <v-row>
                <v-col cols="12" md="6">
                  <Card
                    title="ยอดขายทั้งหมด"
                    :subtitle="`${allTotalCost ? allTotalCost : 0} บาท`"
                    :icon="require('@/assets/tracking.png')"
                    bg_card="#fff"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <Card
                    title="ออเดอร์ทั้งหมด"
                    :subtitle="`${allTotalOrder} รายการ`"
                    :icon="require('@/assets/rating.png')"
                    bg_card="#fff"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-row>
                <v-col cols="12" lg="5">
                  <label
                    >ค้นหาชื่อเฟซบุ๊ก เพจ เบอร์โทรศัพท์ แอดมิน หรือสินค้า</label
                  >
                  <v-text-field
                    hide-details
                    solo
                    dense
                    class="mt-1"
                    v-model="params.search"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="params.date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <label>ค้นหาด้วยวันที่</label>
                      <v-text-field
                        v-model="dateRangeText"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        solo
                        dense
                        class="mt-1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="params.date"
                      no-title
                      scrollable
                      range
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(params.date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  class="mt-lg-7 d-flex justify-end justify-lg-start"
                >
                  <v-btn
                    color="blue"
                    height="38px"
                    @click.prevent="getOrders()"
                    :loading="loadingSearch"
                  >
                    <v-icon left>mdi-magnify</v-icon>
                    ค้นหา
                  </v-btn>
                  <v-btn
                    @click.prevent="defaultOrders"
                    class="ml-4"
                    height="38px"
                    color="white"
                    outlined
                    >ค่าเริ่มต้น</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              class="px-0 mb-7"
              v-if="params.date[0] || params.search"
            >
              <v-row>
                <v-col cols="12">
                  <v-card id="card" class="wrapper-card d-flex" color="#2C3036">
                    <v-list-item>
                      <v-list-item-content class="text-right">
                        <v-list-item-title class="card-title-result mb-3"
                          >การค้นหาด้วยวันที่ {{ params.date[0] }}
                          {{
                            params.date[1] ? `ถึง ${params.date[1]}` : ''
                          }}</v-list-item-title
                        >
                        <v-list-item-title class="card-title-result mb-3"
                          >การค้นหา :
                          {{
                            params.search ? params.search : '-'
                          }}</v-list-item-title
                        >
                        <v-list-item-title class="card-title-result"
                          >ยอดขาย :
                          {{ totalCost | currency2 }} บาท</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <div class="wrapper-table mb-1 mt-3">
              <table class="table-table">
                <tr class="table-head">
                  <th>หมายเลขออเดอร์</th>
                  <th>ชื่อ Facebook</th>
                  <th>เบอรโทรศัพท์</th>
                  <th>แอดมิน</th>
                  <th>ชื่อเพจ</th>
                  <th>การชำระเงิน</th>
                  <th>มูลค่าออเดอร์</th>
                  <th>หมายเหตุ</th>
                  <th>วันที่</th>
                  <th>การจัดส่ง</th>
                  <th>จัดการ</th>
                </tr>
                <tr v-if="orders.length === 0">
                  <td colspan="10">
                    <template>
                      <div
                        style="font-size: 20px"
                        class="d-flex flex-column align-center red--text my-4"
                      >
                        <v-img
                          class="mt-2 mb-4"
                          width="200"
                          src="@/assets/no-data.svg"
                          alt=""
                        >
                        </v-img>
                        ไม่พบข้อมูลรายการออเดอร์
                      </div>
                    </template>
                  </td>
                </tr>
                <tr
                  class="table-body"
                  v-for="(order, index) in orders"
                  :key="index"
                >
                  <td>{{ generateOrderNumber(order.order_id) }}</td>
                  <td>{{ order.facebook_name }}</td>
                  <td>{{ order.phone }}</td>
                  <td>{{ order.username }}</td>
                  <td>{{ order.page ? order.page : '-' }}</td>
                  <td>{{ order.status }}</td>
                  <td>{{ order.cost | currency2 }}</td>
                  <td>{{ order.remark ? order.remark : '-' }}</td>
                  <td>{{ formatDateTime(order.created_date) }}</td>
                  <td :style="order.check === 1 ? 'color: red' : 'color: #000'">
                    {{ order.check === 1 ? 'ยังไม่จัดส่ง' : 'จัดส่งเเล้ว' }}
                  </td>
                  <td>
                    <v-btn
                      class="white--text"
                      small
                      color="success"
                      @click.prevent="updateStatus(order.order_id)"
                      v-if="order.check === 1"
                      >จัดส่งแล้ว</v-btn
                    >
                    <v-btn
                      class="white--text ml-2"
                      small
                      color="primary"
                      @click.prevent="openModalOrderDetail(order.order_id)"
                      >ดูรายละเอียด</v-btn
                    >

                    <v-btn
                      class="white--text ml-2"
                      small
                      color="warning"
                      @click.prevent="onOpenModalEdit(order)"
                      >แก้ไข</v-btn
                    >

                    <v-btn
                      class="ml-2 white--text ml-2"
                      small
                      color="red"
                      @click.prevent="deleteOrder(order.order_id)"
                      v-if="order.check === 1"
                      >ลบ</v-btn
                    >
                  </td>
                </tr>
              </table>
            </div>
            <div class="text-center mt-2">
              <v-pagination
                v-model="params.page"
                :length="totalPages"
                :total-visible="7"
                @input="getOrders"
              ></v-pagination>
            </div>
            <v-col cols="12" class="px-0 mt-7">
              <v-card color="#2C3036">
                <v-container>
                  <div class="title-product-order">
                    จำนวนการขายสินค้า
                  </div>
                  <v-divider color="white" class="my-5"></v-divider>
                  <v-col cols="12">
                    <v-row>
                      <v-col
                        cols="12"
                        class="text-center red--text"
                        v-if="productsQuantity.length === 0"
                      >
                        ไม่มีการซื้อสินค้า
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-for="(data, index) in productsQuantity"
                        :key="index"
                      >
                        <div class="product-name-list">
                          {{ index + 1 }}. {{ data.product_name }}
                        </div>
                        <div class="product-quantity-list">
                          {{ data.sum | currency2 }} จำนวน
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card>
            </v-col>
          </v-container>
        </v-card>
      </v-container>

      <div class="modal-edit-tracking-list" v-if="modal === true">
        <div class="modal-title mt-1 mb-4">แก้ไขข้อมูล</div>
        <v-divider color="white" class="mb-4"></v-divider>
        <v-form
          v-on:submit.prevent="submitEditOrder()"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col cols="12" md="6">
                <label for="">แอดมิน</label>
                <v-autocomplete
                  v-model="order.admin_id"
                  :items="adminItems"
                  item-text="username"
                  item-value="id"
                  solo
                  dense
                  class="mt-2"
                  :rules="adminRule"
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col cols="7" md="6">
                <label for="">สถานะการชำระ</label>
                <v-select
                  :items="paymentStatusItems"
                  item-text="text"
                  item-value="id"
                  solo
                  dense
                  class="mt-2"
                  v-model="order.payment_status_id"
                  @change="handleOutstandBalance()"
                  :rules="paymentStatusRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols="5" md="6" v-if="order.payment_status_id === 1">
                <label for="">ยอดชำระมัดจำ</label>
                <v-text-field
                  v-model="order.deposit"
                  type="number"
                  solo
                  dense
                  class="mt-2"
                  @change="handleOutstandBalance()"
                  :rules="depositRule"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col cols="7" md="6">
                <label for="">มูลค่าออเดอร์</label>
                <v-text-field
                  v-model="order.cost"
                  type="number"
                  solo
                  dense
                  @change="handleOutstandBalance()"
                  :rules="costRule"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="5" md="6" class="text-right">
                <label for="">ค้างชำระ</label>
                <p class="outstand-balance">฿{{ order.outstand_balance }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col cols="12" md="6">
                <label for="">หมายเหตุ</label>
                <v-text-field
                  solo
                  dense
                  v-model="order.remark"
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" xl="12" class="px-0 text-right">
            <v-btn
              :loading="loading"
              large
              color="blue"
              @click.prevent="submitEditOrder()"
            >
              <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
              บันทึกการแก้ไข
            </v-btn>
            <v-btn
              class="ml-6"
              large
              outlined
              color="red"
              @click.prevent="closeModal()"
              >ยกเลิก</v-btn
            >
          </v-col>
        </v-form>
      </div>

      <div class="modal-order-detail-wrapper" v-if="isModalOrderDetail">
        <div class="modal-title mb-6">
          รายการสินค้าออเดอร์
          {{
            orderDetail.length > 0 &&
              generateOrderNumber(orderDetail[0].order_id)
          }}
        </div>
        <v-divider color="white" class="mb-4"></v-divider>
        <div class="wrapper-table mb-1 mt-6">
          <table class="table-table">
            <tr class="table-head">
              <th>#</th>
              <th>ชื่อสินค้า</th>
              <th>จำนวน</th>
              <th>ราคา</th>
              <th>ประเภทสินค้า</th>
              <th>จัดการ</th>
            </tr>
            <tr
              class="table-body"
              v-for="(data, index) in orderDetail"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ data.product_name }}</td>
              <td>{{ data.quantity }}</td>
              <td>{{ data.price | currency2 }}</td>
              <td>{{ data.product_type }}</td>
              <td>
                <v-btn
                  class="white--text"
                  small
                  color="orange"
                  @click.prevent="
                    updateOrderDetailAction(
                      'noti',
                      data.product_order_id,
                      data.order_id
                    )
                  "
                  v-if="data.noti === 1 || !data.noti"
                  >แจ้งชำระลูกค้าแล้ว</v-btn
                >
                <v-btn
                  class="white--text ml-2"
                  small
                  color="red"
                  @click.prevent="
                    updateOrderDetailAction(
                      'paid',
                      data.product_order_id,
                      data.order_id
                    )
                  "
                  v-if="data.paid === 1 || !data.paid"
                  >ลูกค้าชำระแล้ว</v-btn
                >
              </td>
            </tr>
          </table>
        </div>
        <div class="text-right mt-6">
          <v-btn outlined color="red" @click.prevent="closeModalOrderDetail()"
            ><v-icon left>mdi-close</v-icon> ปิด</v-btn
          >
        </div>
      </div>
    </v-main>

    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import HeaderPage from '@/components/HeaderPage.vue';
import FooterAdmin from '@/components/FooterAdmin';
import Card from '@/components/Card';

import moment from 'moment';
import Swal from 'sweetalert2';

import OrderService from '@/services/Order.service.js';
import AdminService from '@/services/Admin.service';
export default {
  name: 'Orders',
  components: {
    Navigation,
    HeaderPage,
    FooterAdmin,
    Card,
  },
  data() {
    return {
      menu: false,
      loading: false,
      loadingSearch: false,
      valid: true,
      pages: 1,
      orders: [],
      params: {
        search: '',
        date: [],
        page: 1,
        limit: 50,
      },
      totalPages: 1,
      totalElement: 0,
      totalAllCost: 0,
      totalCost: 0,
      allTotalCost: 0,
      allTotalOrder: 0,
      productsQuantity: [],

      modal: false,

      isModalOrderDetail: false,
      orderDetail: [],

      order: {
        order_id: null,
        admin_id: null,
        payment_status_id: null,
        deposit: 0,
        outstand_balance: 0,
        cost: 0,
        remark: '',
      },

      paymentStatusItems: [
        { id: 1, text: 'ชำระมัดจำ' },
        { id: 2, text: 'ไม่ชำระมัดจำ' },
        { id: 3, text: 'ชำระเต็มจำนวน' },
      ],
      productTypeItems: ['สินค้าในสต็อคพร้อมส่ง', 'สินค้าพรีออเดอร์'],
      adminItems: [],
      productItems: [],

      adminRule: [(v) => !!v || 'กรุณาเลือกแอดมินที่ทำรายการ'],
      productRule: [(v) => !!v || 'กรุณาเลือกสินค้า'],
      quantityRule: [(v) => !!v || 'กรุณาระบุจำนวน'],
      productTypeRule: [(v) => !!v || 'กรุณาเลือกประเภทสินค้า'],
      paymentStatusRule: [(v) => !!v || 'กรุณาเลือกสถานะการชำระ'],
      depositRule: [(v) => !!v || 'กรุณากรอกยอดมัดจำ'],
      costRule: [(v) => !!v || 'กรุณากรอกมูลค่ารวมออเดอร์'],
    };
  },
  // filters: {
  //   formatDate(date) {
  //     return moment(date)
  //       .subtract(7, 'hours')
  //       .format('DD/MM/YYYY');
  //   },
  // },
  computed: {
    dateRangeText() {
      return this.params.date.join(' - ');
    },
  },
  created() {
    this.getOrders();
  },
  methods: {
    formatDateTime(date) {
      return moment(date)
        .subtract(7, 'hours')
        .format('DD/MM/YYYY, HH:mm');
    },
    generateOrderNumber(number) {
      let orderID = number.toString();
      const maxLength = 8;
      const currentLength = maxLength - orderID.length;
      const prefix = 'OD';
      const prefixLenght = Array.from({ length: currentLength }, () => '0');
      const order = prefixLenght.join('');
      return prefix + order + orderID;
    },
    async defaultOrders() {
      this.params.search = '';
      this.params.date = [];
      this.params.page = 1;
      this.params.limit = 50;
      this.getOrders();
    },
    async getOrders() {
      try {
        this.loadingSearch = true;
        const response = await OrderService.findAll(this.params);
        this.orders = response.orders;
        this.totalPages = response.totalPages;
        this.totalElement = response.totalElement;
        this.productsQuantity = response.productsQuantity;
        this.totalCost = response.totalCost;
        this.allTotalCost = response.allTotalCost;
        this.allTotalOrder = response.allTotalOrder;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loadingSearch = false;
      }
    },
    async updateStatus(orderId) {
      try {
        const response = await OrderService.updateStatus(orderId);
        this.getOrders();
        Swal.fire({
          position: 'bottom-end',
          toast: true,
          icon: 'success',
          title: response.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async deleteOrder(orderId) {
      Swal.fire({
        icon: 'question',
        title: 'ยืนยันการลบ?',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#2196F3',
        cancelButtonColor: '#90A4AE',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await OrderService.delete(orderId);
            this.getOrders();
            Swal.fire({
              position: 'bottom-end',
              toast: true,
              icon: 'success',
              title: response.msg,
              showConfirmButton: false,
              timer: 3000,
            });
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: error.msg,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    },
    async getAdmins() {
      try {
        const response = await AdminService.findAll();
        this.adminItems = response.filter(
          (admin) => admin.username !== 'bomdev'
        );
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    async onOpenModalEdit(data) {
      try {
        this.modal = true;
        await this.getAdmins();
        this.order.order_id = data.order_id;
        this.order.admin_id = data.admin_id;
        this.order.payment_status_id = data.payment_status_id;
        this.order.deposit = data.deposit;
        this.order.outstand_balance = data.outstand_balance;
        this.order.cost = data.cost;
        this.order.remark = data.remark;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    closeModal() {
      this.modal = false;
      this.clearDataEdit();
    },
    clearDataEdit() {
      this.order.order_id = null;
      this.order.admin_id = null;
      this.order.payment_status_id = null;
      this.order.deposit = 0;
      this.order.outstand_balance = 0;
      this.order.cost = 0;
      this.order.remark = '';
    },
    async submitEditOrder() {
      this.loading = true;
      try {
        const response = await OrderService.updateOrder(this.order);
        this.getOrders();
        this.loading = false;
        Swal.fire({
          position: 'bottom-end',
          toast: true,
          icon: 'success',
          title: response.msg,
          showConfirmButton: false,
          timer: 3000,
        });
        this.closeModal();
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async getOrderDetail(orderId) {
      try {
        const response = await OrderService.findOne(orderId);
        this.orderDetail = response.order;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    openModalOrderDetail(orderId) {
      this.getOrderDetail(orderId);
      this.isModalOrderDetail = true;
    },
    closeModalOrderDetail() {
      this.isModalOrderDetail = false;
      this.orderDetail = [];
    },
    updateOrderDetailAction(action, id, orderId) {
      Swal.fire({
        icon: 'question',
        title: `${
          action === 'noti'
            ? 'ยืนยันการแจ้งชำระเงินแล้วกับลูกค้า'
            : 'ยืนยันการชำระเงินเเล้วของลูกค้า'
        }`,
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#2196F3',
        cancelButtonColor: '#90A4AE',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await OrderService.updateProductOrderAction({ action, id });
          this.getOrderDetail(orderId);
        }
      });
    },
  },
};
</script>

<style scoped>
.card-title-result {
  color: #fff;
  font-size: 18px;
}
@media (max-width: 550px) {
  .card-title-result {
    font-size: 16px;
  }
}
.title-product-order {
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
  font-weight: 500;
}
.product-name-list,
.product-quantity-list {
  color: #fff;
}

.modal-edit-tracking-list {
  width: 75%;
  max-height: 100vh;
  overflow: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: rgba(32, 34, 38, 0.98);
  padding: 1rem 1.2rem;
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  z-index: 999 !important;
}
.modal-title {
  color: #fff;
  font-size: 22px;
}
.outstand-balance {
  font-size: 24px;
  color: #fff;
  margin-top: 6px;
  white-space: nowrap;
}
@media (max-width: 500px) {
  .modal-edit-tracking-list {
    width: 85%;
  }
}

.modal-order-detail-wrapper {
  position: fixed;
  border-radius: 3px;
  width: 75%;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
  bottom: 24px;
  z-index: 10;
  background: rgba(32, 34, 38, 0.98);
  padding: 1.4rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}
.close-modal-order-detail-button {
  position: absolute;
  top: 28px;
  right: 18px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .modal-order-detail-wrapper {
    padding: 1rem;
    width: 90%;
  }
  .close-modal-order-detail-button {
    top: 24px;
  }
}
</style>
